<script setup>
import { Toast } from 'vant';
import { ref, inject, reactive } from 'vue';
import { computationalAccuracy } from '@/plugin/public.js';

const $require = inject('$require');
let pageData = ref(null);
let maskShow = ref(false);
let payPrice = ref(0);
let { router, route } = inject('ctx');
/***
 *
 * 页面数据
 */
let getDetail = () => {
  $require
    .HttpPost('/h5/payment/getPayMessage', {
      contract_fee_name: 'rent_pay',
    })
    .then((res) => {
      if (res.data) {
        // res.data.maxValue = 0;
        if (res.data.activity_image) {
          try {
            res.data.activity_image = JSON.parse(res.data.activity_image);
          } catch {
            res.data.activity_image = [res.data.activity_image];
          }
        }

        if (res.data.price_list) {
          res.data.price_list.forEach((element) => {
            element.selected = false;
            let arr = [];
            element.hasmore = false;
            if (element.price_list_info) {
              element.price_list_info.forEach((item) => {
                if (item.type == 2) {
                  // res.data.maxValue += item.price * 1;
                  arr.push(item);
                  element.hasmore = true;
                }
              });
            }
            // price
            element.moreInfo = arr;
          });
        }

        // res.data.maxValue = parseFloat(res.data.key_pay_price_all+res.data.maxValue).toFixed(2);
        if (res.data.balance * 1 < res.data.key_pay_price_all * 1) {
          res.data.haszk = true;
        } else {
          res.data.haszk = false;
        }
        pageData.value = res.data;
        payPrice.value = res.data.key_pay_price;
         if (res.data.owe_type != 1 && res.data.is_activity == 1) {
          calcMax();
        }
      }
    });
};
function hasHttp(item) {
  if (item.includes('http')) {
    return item;
  }
  return process.env.VUE_APP_IMG_URL + item;
}
getDetail();
/**
 * 输入框失去焦点
 */
let inputChange = () => {
  // console.log(isNaN(payPrice.value),typeof payPrice.value);
  // if (isNaN(payPrice.value) || payPrice.value < 0) {
  //   payPrice.value = 0;
  //   return Toast("请输入正确金额");
  // }
  calcMax();
};
let calcMax = () => {
  let keyblance = pageData.value.balance || 0; //应缴金额
  let allPrice = pageData.value.key_pay_price_all;
  //if (pageData.value.owe_type == 1 && pageData.value.is_activity == 1) {
  if (pageData.value.is_activity == 1) {
    if (payPrice.value * 1 > allPrice * 1 && pageData.value.haszk) {
      // console.log(allPrice , keyblance , pageData.value.residue_price,pageData.value.activity_discount);
      // let value = ((allPrice - keyblance - pageData.value.residue_price) * pageData.value.activity_discount).toFixed(2) * 1;
      Toast(`最大可缴金额为${allPrice}`);
      payPrice.value = allPrice;
    }
  }
};
// 查看更多
let checkMore = (item) => {
  item.isrotate = !item.isrotate;
};
/*
  查看查费明细
*/
let checkChargeDetail = () => {
  if (pageData.value.price_list && !isSelect.value) {
    return Toast('请选择需要缴纳的合同');
  }
  if (!payPrice.value) {
    return Toast('请输入金额');
  }

  maskShow.value = true;
};
// 计算减免后金额
let residue_price = ref(0); //减免
let balance = ref(0); //余额抵扣
let discount_money = ref(0); //折扣减免
let calcSum = () => {
  let sum = 0;
  let maxValue = pageData.value.key_pay_price_all;
  if (pageData.value.owe_type == 1 && bill_ids.value.length > 0) {
    let jm = Number(pageData.value.residue_price) || 0;
    let ye = Number(pageData.value.balance) || 0;
    //减免金额;
    if (jm > payPrice.value) {
      residue_price.value = payPrice.value;
      sum = 0;
      discount_money.value = 0;
      return sum;
    }
    //  可减免金额小于缴费金额
    residue_price.value = jm;
    if (ye > payPrice.value - jm) {
      sum = 0;
      balance.value = (payPrice.value - jm).toFixed(2) * 1;
      discount_money.value = 0;
      return sum;
    }
    //  余额大于缴费金额减去抵扣金额等于本身
    balance.value = ye;
    //  活动
    if (pageData.value.is_activity == 1) {
      sum = (payPrice.value - jm - ye) * pageData.value.activity_discount;
      console.log(payPrice.value, jm, ye, sum);
      discount_money.value = (payPrice.value - jm - ye - sum).toFixed(2) * 1;
    } else {
      sum = (payPrice.value - jm - ye).toFixed(2) * 1;
    }
  } else if (maxValue > 0 && pageData.value.is_activity == 1 && pageData.value.haszk) {
    sum = payPrice.value * pageData.value.activity_discount;
    discount_money.value = payPrice.value - sum;
  } else {
    sum = payPrice.value;
  }

  return parseFloat(sum).toFixed(2) * 1;
};
//获取工作人员列表
let usersref = ref(null);
let userList = ref('');
const handleInfoError = () => {
  $require
    .HttpPost('/h5/user/userList', {
      pageNum: 1,
      pageSize: 100,
      property_status: 10,
    })
    .then((res) => {
      if (res.data) {
        userList.value = res.data.list;
        usersref.value.fade();
      }
    });
};

// 确认缴费

let confirmCharge = () => {
  let chargeForm = {
    contract_fee_name: 'rent_pay',
    discount: pageData.value.activity_discount,
    coupon_price: residue_price.value,
    balance_deduction: balance.value,
    discount_money: discount_money.value,
    key_pay_price: calcSum(),
    bill_ids: bill_ids.value,
  };
  $require.HttpPost('/h5/payment/weChatNotice', chargeForm).then((res) => {
    // $require
    //   .HttpPost("/h5/payment/weChatReturn", { orderNo: res.data, status: 1 })
    //   .then(() => {
    //     maskShow.value = false;
    //     getDetail();
    //     setTimeout(() => {
    //       router.push("/contribute/index");
    //     }, 500);
    //   });
    if (calcSum() == 0) {
      $require.HttpPost('/h5/payment/weChatReturn', { orderNo: res.data, status: 1 })
        .then(() => {
          maskShow.value = false;
          getDetail();
        });
    } else {
      window.location.href = res.data.url;
      maskShow.value = false;
      getDetail();
      setTimeout(() => {
          router.push('/contribute/index');
        }, 500);
    }
  });
};
function stop(e) {
  // e.preventDefault()
  //  console.log('aa');
}
// window.addEventListener('touchmove',stop,{passive:false})
let bill_ids = ref([]);
let isSelect = ref(false);
function selected(e, item) {
  isSelect.value = false;
  bill_ids.value = [];
  let total = 0;
  let arr = pageData.value.price_list;
    arr.forEach((item) => {
        if (item.selected) {
    let list = item.price_list_info ? item.price_list_info : [];
    list.forEach((items) => {
        isSelect.value = true;
        if (items.type == 1 || items.type == 0) {
          total += items.price;
        }
      if (items.type != 2) {
        bill_ids.value.push(items.bill_id);
      }
    });
      }
  });
  // console.log(bill_ids)
  let discount = pageData.value.is_activity == 1 ? pageData.value.activity_discount : 1;
   pageData.value.key_pay_price = total;
  // total = ((total - pageData.value.residue_price) * discount).toFixed(2) * 1;
  payPrice.value = total;
}
</script>
<template>
  <div class="rental" v-if="pageData">
    <!-- <img
      v-if="pageData.is_activity == 1"
      :src="pageData.activity_image"
      style="margin-bottom: 10px; width: 100%"
    /> -->
    <van-swipe
      class="my-swipe"
      v-if="pageData.is_activity == 1"
      :autoplay="3000"
      indicator-color="white"
    >
      <van-swipe-item v-for="(item, index) in pageData.activity_image" :key="index">
        <img class="activeImage" :src="hasHttp(item)" alt="" />
      </van-swipe-item>
    </van-swipe>
    <div class="pubBox">
      <div class="list">
        <div class="listTitle">缴费单位</div>
        <div>{{ pageData.market_name }}</div>
      </div>
      <div class="list">
        <div class="listTitle">户主信息</div>
        <div>{{ pageData.contact_name }}</div>
      </div>
      <div class="list">
        <div class="listTitle tw">缴费摊位</div>
        <div class="stall">{{ pageData.merchants_stall }}</div>
      </div>
      <div class="line"></div>
      <div class="list">
        <div class="listTitle">账户余额</div>
        <div>￥{{ pageData.balance }}</div>
      </div>
      <div
        class="font24"
        v-if="pageData.residue_price > 0"
        style="color: #fd6336; text-align: right; flex: 1"
      >
        市场为您减免租金￥{{ pageData.residue_price }}
      </div>
    </div>
    <div class="pubBox">
      <div class="fontBold font32 list">本期账单</div>
      <div class="noSearchData" v-if="!pageData.price_list">
        <img src="../../assets/image/search.png" />
        <span>暂未查询到欠费</span>
      </div>
      <template v-else>
        <div v-for="(item, index) in pageData.price_list" :key="index">
          <div style="display: flex">
            <van-checkbox
              icon-size="12px"
              v-model="item.selected"
              @change="selected($event, item)"
            ></van-checkbox>
            <span style="margin-left: 10px" class="list fontBold font28"
              >合同编号：{{ item.contract_no }}</span
            >
          </div>
          <div class="line"></div>
          <div v-for="(items, index) in item.price_list_info" :key="index">
            <div class="list" v-if="items.type == 1">
              <div class="listTitle">欠费时间</div>
              <div style="color: #fd6336; font-weight: bold">
                {{ items.data }}
              </div>
            </div>
            <div class="list" v-if="items.type == 1">
              <div class="listTitle">欠费费用</div>
              <div style="color: #fd6336; font-weight: bold">
                {{ items.price }}
              </div>
            </div>
            <div class="list" v-if="items.type == 0">
              <div class="listTitle">应缴时间</div>
              <div>{{ items.data }}</div>
            </div>
            <div class="list" v-if="items.type == 0">
              <div class="listTitle">应缴费用</div>
              <div>{{ items.price }}</div>
            </div>
          </div>
          <div
            class="more font24"
            v-if="item.hasmore"
            @click="item.isrotate = !item.isrotate"
            style="text-align: center; color: #529100"
          >
            <span>更多缴费信息</span>
            <img
              style="margin-left: 5px; width: 12px; height: 12px"
              class="arrMore"
              :class="item.isrotate ? 'rotate' : ''"
              src="../../assets/image/newarrdown.png"
            />
          </div>
          <transition name="down">
            <div
              class="moreInfo"
              :class="item.isrotate ? 'downfade' : ''"
              v-if="item.hasmore && item.isrotate"
            >
              <div class="pubTable">
                <div class="tableTitle">
                  <div>预定缴费时间</div>
                  <div>缴费金额</div>
                </div>
                <div
                  class="tableList"
                  v-for="(items, indexs) in item.moreInfo"
                  :key="indexs"
                >
                  <div>{{ items.data }}</div>
                  <div>￥{{ items.price }}</div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </template>

      <div class="line"></div>
      <div class="list">
        <div class="font28 fontBold">应缴总金额</div>
        <div class="font40 fontBold">￥{{ pageData.key_pay_price || 0 }}</div>
      </div>
      <div class="list" v-if="pageData.price_list && pageData.balance > 0">
        <div class="listTitle">账户余额抵扣</div>
        <div style="color: #fd6336">￥{{ pageData.balance }}</div>
      </div>
      <div class="list" v-if="pageData.is_activity == 1 && pageData.price_list && pageData.haszk">
        <div class="listTitle">活动折扣率</div>
        <div style="color: #fd6336">{{ pageData.activity_discount * 10 }}折</div>
      </div>
    </div>
    <div class="pubBox">
      <div class="fontBold font32 list">缴费金额</div>
      <div
        class="list fontBold font48"
        v-if="pageData.is_change_pay != 1 && pageData.price_list"
      >
        ￥{{ pageData.key_pay_price }}
      </div>
      <div class="inputMoney" v-else>
        <span>￥</span>
        <input

          v-model="payPrice"
          @blur="inputChange"
          placeholder="点击输入缴费金额"
          v-num
        />

        <div class="inputTip" style="display: none">
          缴费金额超过应缴总金额，无法进行加纳，请重新填写缴费金额
        </div>
      </div>
    </div>
    <div
      class="tip"
      v-if="
        (pageData.is_activity == 0 && !pageData.price_list) ||
        (pageData.is_activity == 0 && pageData.price_list)
      "
    >
      超出部分将转为余额。余额可以用于后续账单使用，也可以申请退还。
    </div>
    <div class="tip" v-if="pageData.is_activity == 1 && !pageData.price_list">
      您已无应缴金额，无法参与优惠活动。
    </div>
    <div class="tip" v-if="pageData.is_activity == 1 && pageData.price_list">
      活动最高缴费金额不能超过个人应缴费用总金额。
    </div>
    <div class="btn" @click="checkChargeDetail">确认缴纳</div>
    <div class="help">
      <div @click="$router.push({ path: '/contribute/rentFeeDetail' })">缴费计划</div>
      <div @click="handleInfoError">疑问？</div>
    </div>
    <div class="mask" v-if="maskShow" @touchmove="stop">
      <div class="maskMain">
        <div class="mainTitle">
          支付详情
          <img src="../../assets/image/dele.png" @click="maskShow = false" />
        </div>
        <div class="mainContent">
          <div class="contentList">
            <span class="fontBold" style="color: black">支付金额</span>
            <span class="fontBold" style="color: black; font-size: 24px"
              >￥{{ calcSum() }}</span
            >
          </div>
          <div class="contentList">
            <span>缴费金额</span>
            <span style="color: black">￥{{ payPrice }}</span>
          </div>
          <div class="contentList" v-if="residue_price > 0">
            <span>减免金额</span>
            <span style="color: #fd6336">{{ residue_price }}</span>
          </div>
          <div class="contentList" v-if="balance > 0">
            <span>余额抵扣</span>
            <span style="color: #fd6336">￥{{ balance }}</span>
          </div>
          <div class="contentList" v-if="pageData.is_activity == 1 && discount_money > 0">
            <span>折扣减免</span>
            <span style="color: #fd6336">￥{{ discount_money }}</span>
          </div>
        </div>
        <div class="btns">
          <div @click="maskShow = false">取消</div>
          <div @click="confirmCharge">确认支付</div>
        </div>
      </div>
    </div>
  </div>
  <!-- 工作人员列表 -->
  <rConfirm slots ref="usersref" :btn="false" titles="联系市场人员">
    <div class="linkform">
      <van-cell-group inset>
        <div v-for="item in userList" :key="item.user_id">
          <van-field v-model="item.name" readonly label="市场管理员" />
          <van-field v-model="item.mobile" readonly label="联系电话">
            <template #button>
              <div style="color: #81b51d" @click="$common.callPhone(item.mobile)">
                <van-icon name="phone" />
              </div>
            </template>
          </van-field>
        </div>
      </van-cell-group>
    </div>
  </rConfirm>
  <!-- owe_type 1欠费 -->
</template>

<style scoped lang="scss">
.rental {
  width: 100%;
  overflow-x: hidden;
  padding: 25px;
  box-sizing: border-box;
  font-size: 28px;
  font-weight: 400;
  background-color: #f9f9f9;
  min-height: 100vh;
  .activeImage {
    width: 100%;
    height: 300px;
    object-fit: contain;
  }

  .mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
    box-sizing: border-box;
    touch-action: none;

    .maskMain {
      flex: 1;
      background: #ffffff;
      border-radius: 20px 20px 20px 20px;

      .mainTitle {
        position: relative;
        height: 120px;
        text-align: center;
        line-height: 120px;
        font-size: 36px;
        font-weight: bold;
        color: #000000;
        border-bottom: 2px solid #efefef;

        img {
          position: absolute;
          right: 40px;
          top: 30%;
          transform: translateY(-30%);
        }
      }

      .mainContent {
        padding: 40px;
        box-sizing: border-box;

        .contentList {
          display: flex;
          align-items: center;
          justify-content: space-between;
          line-height: 70px;

          span {
            font-size: 32px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }

      .btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px;
        box-sizing: border-box;
        font-size: 32px;

        div {
          width: 48%;
          height: 96px;
          background: #ffffff;
          line-height: 96px;
          text-align: center;
          border-radius: 200px 200px 200px 200px;
          border: 2px solid rgba(0, 0, 0, 0.1);

          &:last-child {
            background: #85c226;
            border: none;
            color: #ffffff;
          }
        }
      }
    }
  }

  .help {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 50px;

    div {
      text-align: center;
      width: 50%;
      height: 28px;
      font-size: 24px;
      font-weight: 500;
      color: #000000;

      &:first-child {
        border-right: 1px solid #efefef;
      }
    }
  }

  .btn {
    height: 96px;
    background: #85c226;
    border-radius: 200px 200px 200px 200px;
    text-align: center;
    line-height: 96px;
    font-size: 36px;
    color: #ffffff;
    margin: 20px 0;
  }

  .tip {
    flex: 1;
    font-size: 24px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    padding: 0 24px;
    box-sizing: border-box;
  }
  //查看更多箭头
  .arrMore {
    transition: all 0.5s;
  }
  /* 查看箭头旋转 */
  .rotate {
    transform: rotate(180deg);
  }
  .more {
    margin: 30px 0;
  }
  //更多内容出现
  .moreInfo {
    transition: all 0.2s;

    display: flex;
    max-height: 0;
    overflow: hidden;
  }
  .downfade {
    max-height: 600px;
    overflow: auto;
  }
  /*
  过渡动画
  */
  .down-enter,
  .down-leave-to {
    opacity: 0;
  }
  .down-enter-active,
  .down-leave-active {
    transition: all 0.1s;
  }
  .down-enter-to,
  .down-leave {
    opacity: 1;
  }
}
.pubBox {
  .list {
    .tw {
      flex-shrink: 0;
      width: 120px;
      white-space: nowrap;
    }
    .stall {
      // height: 60px;
      // width: 200px;
      overflow-x: auto;
      padding-left: 15px;
      white-space: nowrap;
    }
  }
  .inputMoney {
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: 28px 0;
    input {
      font-size: 48px;
      font-weight: bold;
    }
    input::placeholder {
      color: #cccccc;
      font-size: 48px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
    }
  }
}
</style>
